import React, {createContext, Fragment, useContext, useRef, useState} from 'react';
import {Transition} from '@headlessui/react';
import delayMilliseconds from '@lib/delayMilliseconds';
import useDebounce from '@lib/useDebounce';

interface IFeedbackProviderProps {}

interface IFeedbackContext {
  enqueueFeedback: (message: string, isError?: boolean, hideAfter?: number) => void;
  closeFeedback: () => void;
}

const initialStates: IFeedbackContext = {
  enqueueFeedback: () => {},
  closeFeedback: () => {},
};

export const FeedbackContext = createContext<IFeedbackContext>(initialStates);
export const useFeedback = (): IFeedbackContext => useContext(FeedbackContext);

const FeedbackProvider: React.FunctionComponent<IFeedbackProviderProps> = ({children}) => {
  const [message, setMessage] = useState<string>();
  const [isError, setIsError] = useState<boolean>(false);
  const [show, setShow] = useState<boolean>(false);

  const {debounce} = useDebounce();

  const enqueueFeedback = async (message: string, isError: boolean = false, hideAfter: number = 6000) => {
    setShow(false);
    await delayMilliseconds(200);
    setMessage(message);
    setIsError(isError);
    setShow(true);
    await debounce(hideAfter);
    setShow(false);
  };

  const closeFeedback = () => setShow(false);

  return (
    <FeedbackContext.Provider
      value={{
        enqueueFeedback,
        closeFeedback,
      }}>
      <div className="fixed right-0 top-0 flex items-start justify-end px-4 py-6 sm:p-6 z-100">
        <Transition
          show={show}
          enter="transform ease-in-out duration-200 transition"
          enterFrom="translate-x-full opacity-0"
          enterTo="translate-x-0 opacity-100"
          leave="transform ease-in-out duration-100 transition"
          leaveFrom="translate-y-0 opacity-100"
          leaveTo="-translate-y-2 opacity-0">
          <div className={`rounded-md bg-white border shadow-md p-4 ${isError ? 'border-red-400' : 'border-green-400'}`}>
            <div className="flex items-center">
              <div className="flex-shrink-0">
                {isError ? (
                  <svg className="h-6 w-6 text-red-500" fill="currentColor" viewBox="0 0 20 20">
                    <path
                      fillRule="evenodd"
                      d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z"
                      clipRule="evenodd"></path>
                  </svg>
                ) : (
                  <svg
                    className={`h-6 w-6 ${isError ? 'text-red-400' : 'text-green-400'}`}
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                    aria-hidden="true">
                    <path
                      fillRule="evenodd"
                      d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                      clipRule="evenodd"
                    />
                  </svg>
                )}
              </div>

              <div className="ml-3">
                <p
                  className={`text-sm font-medium
                ${isError ? 'text-red-800' : 'text-green-800'}`}>
                  {(() => {
                    const finalMessage = typeof message === 'string' ? message.split('\n') : message;
                    return finalMessage?.map(line => {
                      return (
                        <Fragment key={line}>
                          {line} <br />
                        </Fragment>
                      );
                    });
                  })()}
                </p>
              </div>

              <div className="ml-auto pl-3">
                <div className="-mx-1.5 -my-1.5">
                  <button
                    className={`inline-flex rounded-md p-1.5 focus:outline-none
                    ${isError ? 'text-red-500 hover:text-red-600' : 'text-green-500 hover:text-green-600'}`}
                    onClick={() => setShow(false)}>
                    <span className="sr-only">Dismiss</span>
                    <svg className="h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                      <path
                        fillRule="evenodd"
                        d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                        clipRule="evenodd"
                      />
                    </svg>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </Transition>
      </div>
      {children}
    </FeedbackContext.Provider>
  );
};

export default FeedbackProvider;
