import firebase from 'firebase/app';
import 'firebase/messaging';

firebase.apps.length
  ? firebase.app()
  : firebase.initializeApp({
      apiKey: 'AIzaSyAsnEbQZ_7s6Xh3ri9c1gQPbExN2UB73W0',
      projectId: 'coox-9b450',
      appId: '1:679713908941:web:2d62799fc97a8baeca6184',
      messagingSenderId: '679713908941',
    });

export const FCM_TOKEN_STRING = 'CONSUMER_FCM_TOKEN';
export default firebase;
