import {useApollo} from '@lib/apolloClient';
import {ApolloProvider} from '@apollo/client';

import React from 'react';
import Error from './_error';
import Head from 'next/head';
import Bugsnag from '@bugsnag/js';

import '../styles/global.css';
import 'tailwindcss/tailwind.css';
import {FeedbackProvider, GeolocationProvider, NotificationProvider, LoaderProvider} from 'context';

const ErrorBoundary = Bugsnag.getPlugin('react')?.createErrorBoundary(React);

function MyApp({Component, pageProps}) {
  const apolloClient = useApollo(pageProps);

  return (
    <>
      <Head>
        <title>Store | COOX</title>
        <link rel="icon shortcut" href="/favicon.ico" />

        <meta name="Description" content="Order your favourite cuisines and tasty treats to satisfy, available via delivery, takeaway and dine-in." />

        <meta property="og:image" content="https://store.coox.asia/store-og-image.jpg" />
        <meta property="og:image:type" content="image/jpg" />
        <meta property="og:image:width" content="200" />
        <meta property="og:image:height" content="200" />

        <meta property="og:title" content="Taste The World On Your Plate | COOX" />
        <meta
          property="og:description"
          content="Order your favourite cuisines and tasty treats to satisfy, available via delivery, takeaway and dine-in."
        />
        <meta property="og:url" content="https://store.coox.asia" />

        <meta name="facebook-domain-verification" content="01fbgg6gui8mx52fk839eiaxrqok8c" />
      </Head>

      {/* @ts-ignore */}
      <ErrorBoundary FallbackComponent={Error}>
        <ApolloProvider client={apolloClient}>
          <FeedbackProvider>
            <GeolocationProvider>
              <NotificationProvider>
                <LoaderProvider>
                  <section className="flex justify-center min-h-screen">
                    <div className="max-w-md bg-bgColor w-full shadow-md select-none ">
                      <Component {...pageProps} />
                    </div>
                  </section>
                </LoaderProvider>
              </NotificationProvider>
            </GeolocationProvider>
          </FeedbackProvider>
        </ApolloProvider>
      </ErrorBoundary>
    </>
  );
}

export default MyApp;
