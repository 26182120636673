import React, {createContext, useContext, useEffect} from 'react';
import firebase, {FCM_TOKEN_STRING} from '@lib/firebase';
import {parseCookies, setCookie} from 'nookies';
import {useFeedback} from './feedbackContext';
import {JWT_STRING} from '@lib/apolloClient';
import {useUpdateGcmTokenMutation} from '@graphql/generated/graphql';

interface INotificationProviderProps {}
interface INotificationContext {}

export const NotificationContext = createContext<INotificationContext>({});
export const useNotification = (): INotificationContext => useContext(NotificationContext);

const NotificationProvider: React.FunctionComponent<INotificationProviderProps> = ({children}) => {
  const {enqueueFeedback} = useFeedback();
  const [updateGcmToken] = useUpdateGcmTokenMutation();

  const firebaseMessaging = async (messaging: firebase.messaging.Messaging) => {
    try {
      const oldToken = parseCookies()[FCM_TOKEN_STRING];
      const newToken = await messaging.getToken();
      if (newToken !== oldToken && parseCookies()[JWT_STRING]) {
        await updateGcmToken({variables: {idToAdd: newToken, ...(oldToken ? {idToDelete: oldToken} : {})}});
      }
      setCookie(null, FCM_TOKEN_STRING, newToken);
      messaging.onMessage(payload => {
        enqueueFeedback(payload.notification.body);
      });
    } catch (e) {
      console.warn('Please enable notification to get order updates');
    }
  };

  useEffect(() => {
    if (!firebase.messaging.isSupported()) return;
    const messaging = firebase.messaging();
    firebaseMessaging(messaging);
  }, []);

  return <NotificationContext.Provider value={{}}>{children}</NotificationContext.Provider>;
};

export default NotificationProvider;
