import Bugsnag from '@bugsnag/js';

type ResType = {
  ip: string;
  loc: string;
};

const myIP = async () => {
  try {
    const res = await fetch('https://www.cloudflare.com/cdn-cgi/trace');
    const text = await res.text();
    const dataEntry = text.split('\n').map(t => t.split('='));
    const data = Object.fromEntries(dataEntry) as ResType;
    const country = data.loc;
    return {data, country};
  } catch (error: any) {
    Bugsnag.notify(error);
    return {data: null, country: ''};
  }
};

export default myIP;
