import React, {createContext, useContext, useEffect, useState} from 'react';
import {useRouter} from 'next/router';
import {MoonLoader} from 'react-spinners';

interface ILoaderProviderProps {}
interface ILoaderContext {
  setShowLoader: React.Dispatch<React.SetStateAction<boolean>>;
}

const initialStates: ILoaderContext = {
  setShowLoader: () => {},
};

export const LoaderContext = createContext<ILoaderContext>(initialStates);
export const useLoader = (): ILoaderContext => useContext(LoaderContext);

const LoaderProvider: React.FC<ILoaderProviderProps> = ({children}) => {
  const router = useRouter();
  const [showLoader, setShowLoader] = useState<boolean>(false);

  useEffect(() => {
    setShowLoader(false);
  }, [router.push]);

  return (
    <LoaderContext.Provider value={{setShowLoader}}>
      {showLoader && (
        <section className="fixed inset-0 max-w-md mx-auto flex justify-center items-center bg-white opacity-75 z-90">
          <MoonLoader loading={true} size={30} speedMultiplier={0.8} />
        </section>
      )}
      {children}
    </LoaderContext.Provider>
  );
};

export default LoaderProvider;
