import merge from 'deepmerge';
import isEqual from 'lodash/isEqual';
import {useMemo} from 'react';
import {ApolloClient, ApolloLink, HttpLink, InMemoryCache} from '@apollo/client';
import {setContext} from '@apollo/client/link/context';
import {parseCookies} from 'nookies';

export const APOLLO_STATE_PROP_NAME = '__APOLLO_STATE__';
export const JWT_STRING = 'coox.consumer.jwt';

export function getApolloClient(ctx?: any) {
  const authLink = setContext(async (_, {headers}) => {
    const jwt = parseCookies(ctx)[JWT_STRING];
    return {
      headers: {
        ...headers,
        Authorization: jwt ?? '',
      },
    };
  });

  const httpLink = new HttpLink({
    uri: process.env.GRAPHQL_URL,
    credentials: 'include',
  });

  const client = new ApolloClient({
    ssrMode: typeof window === 'undefined',
    link: ApolloLink.from([authLink, httpLink]),
    cache: new InMemoryCache({
      dataIdFromObject: object => `${object.__typename}${object.id}`,
      typePolicies: {
        Query: {
          fields: {
            mixOrders: {
              keyArgs: false,
              merge(existing = [], incoming) {
                return [...existing, ...incoming];
              },
            },
          },
        },
      },
    }),
  });

  return client;
}

export function initializeApollo(initialState = null) {
  const _apolloClient = getApolloClient();
  if (initialState) {
    const existingCache = _apolloClient.extract();
    const data = merge(initialState, existingCache, {
      arrayMerge: (destinationArray, sourceArray) => [...sourceArray, ...destinationArray.filter(d => sourceArray.every(s => !isEqual(d, s)))],
    });
    _apolloClient.cache.restore(data);
  }

  return _apolloClient;
}

export function useApollo(pageProps) {
  const state = pageProps[APOLLO_STATE_PROP_NAME];
  const store = useMemo(() => initializeApollo(state), [state]);
  return store;
}
