import {create} from 'zustand';
import {persist} from 'zustand/middleware';
import {produce} from 'immer';
import {DeliveryService, PaymentMethod} from '@graphql/generated/graphql';

export type CartOutletType = {
  deliveryFee: number;
  deliveryInstruction: string;
  deliveryMetadata: string;
  discount: DiscountType;
  stores: CartStoreType[];
};

type DiscountType = {
  code: string;
  data: {
    code: string;
    storeFriendlyPath: string;
    amount: number;
    message: string;
  }[];
};

export type CartStoreType = {
  storeId: string;
  country: string;
  checked: boolean;
  menuItems: CartStoreMenuItemType[];
};

export type CartStoreMenuItemType = {
  menuItemId: string;
  menuOptions: CartStoreMenuOptionType[];
  subtotal: number;
  tax: number;
  remark: string;
  count: number;
};

export type CartStoreMenuOptionType = {
  menuOptionId: string;
  itemOptions: CartStoreItemOptionType[];
};

type CartStoreItemOptionType = {
  itemOptionId: string;
  itemOptionName: string;
};

const defaultCartOutlet: CartOutletType = {
  deliveryFee: 0,
  deliveryInstruction: '',
  deliveryMetadata: '',
  discount: {code: '', data: []},
  stores: [],
};

const initialState = {
  cart: {} as Record<string, CartOutletType>,
  deliveryService: DeliveryService.Pandago as DeliveryService,
  paymentMethod: PaymentMethod.Grabpay as PaymentMethod,
  cutlery: false as boolean,
};

type PersistentStoreState = typeof initialState & {
  getMixOrderInput: (outletFriendlyPath: string) => CartOutletType;
  setMixOrderInput: (outletFriendlyPath: string, input: Partial<CartOutletType>) => void;
  setDeliveryService: (deliveryService: DeliveryService) => void;
  setPaymentMethod: (paymentMethod: PaymentMethod) => void;
  setCutlery: (cutlery: boolean) => void;
  removeCartOutlet: (outletFriendlyPath: string) => void;
  resetStore: () => void;
};

const useMixOrderStore = create<PersistentStoreState>()(
  persist(
    (set, get) => ({
      ...initialState,
      getMixOrderInput: outletFriendlyPath => {
        const cartOutlet = get().cart[outletFriendlyPath];
        if (cartOutlet) return cartOutlet;
        set({cart: {...get().cart, [outletFriendlyPath]: defaultCartOutlet}});
        return defaultCartOutlet;
      },
      setMixOrderInput: (outletFriendlyPath, input) => {
        const newCart = produce(get().cart, draft => {
          draft[outletFriendlyPath] = {...draft[outletFriendlyPath], ...input};
        });
        set({cart: newCart});
      },
      setDeliveryService: deliveryService => set({deliveryService}),
      setPaymentMethod: paymentMethod => set({paymentMethod}),
      setCutlery: cutlery => set({cutlery}),
      removeCartOutlet: outletFriendlyPath => {
        const newCart = produce(get().cart, draft => {
          delete draft[outletFriendlyPath];
        });
        set({cart: newCart});
      },
      resetStore: () => set(initialState),
    }),
    {name: 'coox.consumer.cart.storage'},
  ),
);

export default useMixOrderStore;
