import {useMemo} from 'react';
import {AppMetadataQuery, useAppMetadataQuery} from '@graphql/generated/graphql';

export const getCountryData = (country: string, operatingCountries: AppMetadataQuery['app']['operatingCountries']) => {
  const countryData = operatingCountries?.find(({country: c}) => c === country);
  const currency = countryData?.currency?.symbol ?? '';
  return {countryData, currency};
};

const useCountryData = (country: string) => {
  const {data} = useAppMetadataQuery();

  const countryData = useMemo(() => {
    return data?.app?.operatingCountries?.find(({country: c}) => c === country);
  }, [data]);

  const currency = countryData?.currency?.symbol ?? '';

  return {countryData, currency};
};

export default useCountryData;
