import {create} from 'zustand';
import {persist} from 'zustand/middleware';
import {produce} from 'immer';
import {DeliveryService, PaymentMethod} from '@graphql/generated/graphql';

export type MartCartOutletType = {
  deliveryFee: number;
  deliveryInstruction: string;
  discount: DiscountType;
  stores: MartCartStoreType[];
};

type DiscountType = {
  code: string;
  data: {
    code: string;
    storeFriendlyPath: string;
    amount: number;
    message: string;
  }[];
};

export type MartCartStoreType = {
  storeId: string;
  menuItems: MartCartStoreMenuItemType[];
};

type MartCartStoreMenuItemType = {
  menuItemId: string;
  subtotal: number;
  tax: number;
  count: number;
};

const defaultCartOutlet: MartCartOutletType = {
  deliveryFee: 0,
  deliveryInstruction: '',
  discount: {
    code: '',
    data: [],
  },
  stores: [],
};

const initialState = {
  cart: {} as Record<string, MartCartOutletType>,
  deliveryService: DeliveryService.Pandago as DeliveryService,
  paymentMethod: PaymentMethod.Grabpay as PaymentMethod,
  cutlery: false as boolean,
};

type PersistentStoreState = typeof initialState & {
  getMartOrderInput: (outletFriendlyPath: string) => MartCartOutletType;
  setMartOrderInput: (outletFriendlyPath: string, input: Partial<MartCartOutletType>) => void;
  setDeliveryService: (deliveryService: DeliveryService) => void;
  setPaymentMethod: (paymentMethod: PaymentMethod) => void;
  removeCartOutlet: (outletFriendlyPath: string) => void;
  resetStore: () => void;
};

const useMartOrderStore = create<PersistentStoreState>()(
  persist(
    (set, get) => ({
      ...initialState,
      getMartOrderInput: outletFriendlyPath => {
        const cartOutlet = get().cart[outletFriendlyPath];
        if (cartOutlet) return cartOutlet;
        set({cart: {...get().cart, [outletFriendlyPath]: defaultCartOutlet}});
        return defaultCartOutlet;
      },
      setMartOrderInput: (outletFriendlyPath, input) => {
        const newCart = produce(get().cart, draft => {
          draft[outletFriendlyPath] = {...draft[outletFriendlyPath], ...input};
        });
        set({cart: newCart});
      },
      setDeliveryService: deliveryService => set({deliveryService}),
      setPaymentMethod: paymentMethod => set({paymentMethod}),
      removeCartOutlet: outletFriendlyPath => {
        const newCart = produce(get().cart, draft => {
          delete draft[outletFriendlyPath];
        });
        set({cart: newCart});
      },
      resetStore: () => set(initialState),
    }),
    {name: 'coox.consumer.mart.storage'},
  ),
);

export default useMartOrderStore;
