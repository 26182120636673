import {create} from 'zustand';
import {persist} from 'zustand/middleware';
import {DeliveryOption} from '@graphql/generated/graphql';
import {produce} from 'immer';

const initialState = {
  showPopUp: {} as Record<string, boolean>,
  deliveryOption: DeliveryOption.DineIn as DeliveryOption,
};

type PersistentStoreState = typeof initialState & {
  setPopUpShown: (name: string) => void;
  setDeliveryOption: (deliveryOption: DeliveryOption) => void;
  resetStore: () => void;
};

const usePersistentStore = create<PersistentStoreState>()(
  persist(
    (set, _get) => ({
      ...initialState,
      setPopUpShown: name => {
        set(prev => ({
          showPopUp: produce(prev.showPopUp, draft => {
            draft[name] = true;
          }),
        }));
      },
      setDeliveryOption: deliveryOption => set({deliveryOption}),
      resetStore: () => set(initialState),
    }),
    {
      name: 'coox.consumer.app.storage',
      partialize: ({deliveryOption}) => ({deliveryOption}),
    },
  ),
);

export default usePersistentStore;
